import { Box, Button, Text, Flex, useToast } from "@chakra-ui/react";
import { useRouter } from "next/router";
import { FC, useState } from "react";
import getConfig from "next/config";

interface PreviewProps {
  isPreview: boolean;
}

export const Preview: FC<PreviewProps> = ({ isPreview }) => {
  const router = useRouter();
  const toast = useToast();
  const { publicRuntimeConfig } = getConfig();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleAlert = async (response: Response) => {
    const parsed = await response.json();
    toast({
      status: response.ok ? "success" : "warning",
      title: parsed.message,
      position: "bottom",
      isClosable: true,
    });
  };

  const onExit = () => {
    router.push("/api/exitPreviewMode");
  };

  const onRebuild = async () => {
    setIsLoading(true);
    const currentPath = router.asPath;
    const response = await fetch(
      `/api/revalidate?path=${currentPath}&secret=${publicRuntimeConfig.buildToken}`,
      {
        credentials: "omit",
      }
    );
    handleAlert(response);
    setIsLoading(false);
  };

  if (isPreview) {
    return (
      <Box bg="green.900" zIndex={10} pos="sticky" w="full" top="0" p="10px">
        <Flex justifyContent="center" alignItems="center" gap="10px">
          <Text textColor="white">Preview Mode</Text>
          <Button onClick={onRebuild} isLoading={isLoading} h="10px">
            Build
          </Button>
          <Button onClick={onExit} variant="secondary" h="10px">
            Exit
          </Button>
        </Flex>
      </Box>
    );
  }
  return null;
};

export default Preview;
