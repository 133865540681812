const Heading = {
  variants: {
    h1: {
      fontSize: "85px",
      lineHeight: "108px",
    },
    h2: {
      fontSize: "60px",
      lineHeight: "80px",
    },
    h3: {
      fontSize: "40px",
      lineHeight: "56px",
    },
    h4: {
      fontSize: "32px",
      lineHeight: "40px",
    },
    h5: {
      fontSize: "24px",
      lineHeight: "36px",
    },
  },
};

const Text = {
  variants: {
    "sub-h1": {
      fontSize: "24px",
      lineHeight: "32px",
    },
    "sub-h2": {
      fontSize: "21px",
      lineHeight: "32px",
    },
    "paragraph-1": {
      fontSize: "18px",
      lineHeight: "27px",
    },
    "paragraph-2": {
      fontSize: "16px",
      lineHeight: "24px",
    },
    "paragraph-2-1": {
      fontSize: "16px",
      lineHeight: "27px",
    },
    "paragraph-3": {
      fontSize: "14px",
      lineHeight: "21px",
    },
    "learn-more-cta": {
      fontSize: "16px",
      lineHeight: "24px",
      // textDecorationLine: "underline",
      // textDecorationThickness: "2px",
      // textUnderlineOffset: "4px",
      color: "primary.darkgreen",
    },
    navItem: {
      fontSize: "16px",
      lineHeight: "24px",
      whiteSpace: "nowrap",
      color: "#153C3C",
      _hover: { cursor: "pointer" },
    },
    mobileNavItem: {
      fontSize: "18px",
      lineHeight: "27px",
    },
  },
  defaultProps: {
    variant: "paragraph-1",
  },
};

const Button = {
  baseStyle: {
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "24px",
    borderRadius: "4px",
  },
  sizes: {
    desktop: {
      h: "56px",
      px: "24px",
      py: "16px",
    },
    tablet: {
      h: "48px",
      px: "24px",
      py: "12px",
    },
    mobile: {
      maxW: "326px",
      h: "45px",
      py: "10.5px",
    },
  },
  variants: {
    primary: {
      bgColor: "primary.darkgreen",
      color: "white",
      _hover: {
        bgColor: "#226A2E",
      },
      _disabled: {
        opacity: "0.2",
      },
      _active: {
        bgColor: "#226A2E",
        boxShadow:
          "0px 1px 2px rgba(0, 0, 0, 0.05), 0px 0px 0px 2px #FFFFFF, 0px 0px 0px 4px #226A2E",
      },
    },
    outlined: {
      color: "#2D7339",
      border: "2px solid #226A2E",
      _hover: {
        bgColor: "#226A2E",
        color: "white",
      },
      _disabled: {
        opacity: "0.2",
      },
      _active: {
        bgColor: "#226A2E",
        boxShadow:
          "0px 1px 2px rgba(0, 0, 0, 0.05), 0px 0px 0px 2px #FFFFFF, 0px 0px 0px 4px #226A2E",
      },
    },
    secondary: {
      bgColor: "red.600",
      color: "white",
      _hover: {
        bgColor: "red.700",
      },
    },
  },
  defaultProps: {
    variant: "primary",
    size: "desktop",
  },
};

const Input = {
  baseStyle: {
    field: {
      px: "24px",
      borderRadius: "4px",
      background: "secondary.white",
      borderColor: "#DCD7D7",
      borderWidth: "1px",
      borderStyle: "solid",
      _placeholder: {
        color: "secondary.typography",
        opacity: "0.4",
      },
    },
  },
  sizes: {
    desktop: {
      field: {
        height: "56px",
        _placeholder: {
          fontSize: "18px",
          lineHeight: "27px",
        },
      },
    },
    tablet: {
      field: {
        height: "48px",
        _placeholder: {
          fontSize: "16px",
          lineHeight: "27px",
        },
      },
    },
    mobile: {
      field: {
        height: "45px",
        _placeholder: {
          fontSize: "16px",
          lineHeight: "24px",
        },
      },
    },
  },
  variants: {
    primary: {
      field: {
        _focus: {
          borderColor: "primary.darkgreen",
          boxShadow: "none",
        },
      },
    },
  },
  defaultProps: {
    variant: "primary",
    size: "desktop",
  },
};

export const components = {
  Heading,
  Text,
  Button,
  Input,
};
