import Script from "next/script";
import * as React from "react";
import * as fbq from "./pixel";

export const ScriptHead = () => (
  <React.Fragment>
    <Script
      id="pixel"
      strategy="afterInteractive"
      dangerouslySetInnerHTML={{
        __html: `
        !function(f,b,e,v,n,t,s)
        {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
        n.callMethod.apply(n,arguments):n.queue.push(arguments)};
        if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
        n.queue=[];t=b.createElement(e);t.async=!0;
        t.src=v;s=b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t,s)}(window, document,'script',
        'https://connect.facebook.net/en_US/fbevents.js');
        fbq('init', ${fbq.FB_PIXEL_ID});
      `,
      }}
    />
    <Script
      id="linkedin-pixel"
      strategy="afterInteractive"
      dangerouslySetInnerHTML={{
        __html: `(function(l) { if (!l){window.lintrk = function(a,b){window.lintrk.q.push([a,b])}; 
          window.lintrk.q=[]} var s = document.getElementsByTagName("script")[0];
          var b = document.createElement("script"); b.type = "text/javascript";
          b.async = true; b.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js";
          s.parentNode.insertBefore(b, s);})(window.lintrk);`,
      }}
    />
  </React.Fragment>
);
export default ScriptHead;
