import { ApolloProvider } from "@apollo/client";
import { ChakraProvider, CSSReset } from "@chakra-ui/react";
import Preview from "@components/Preview";
import StructuredData from "@components/StructuredData";
import "@fontsource/vollkorn/400.css";
import apolloClient from "apollo/apollo-client";
import Fonts from "fonts";
import * as gtag from "lib/analytics";
import type { NextPage } from "next";
import type { AppProps } from "next/app";
import { useRouter } from "next/router";
import { ReactElement, ReactNode, useEffect } from "react";
import "swiper/css";
import "swiper/css/pagination";
import { theme } from "theme";
import * as fbq from "../lib/pixel";
import ScriptHead from "../lib/script";

type NextPageWithLayout = NextPage & {
  getLayout?: (page: ReactElement) => ReactNode;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

function MyApp({
  Component,
  pageProps,
  router: isPreviewRouter,
}: AppPropsWithLayout) {
  const getLayout = Component.getLayout ?? ((page) => page);
  const router = useRouter();
  const isPreview = isPreviewRouter.isPreview;
  useEffect(() => {
    fbq.pageview();
    const handleRouteChange = (url: URL) => {
      gtag.pageview(url);
      fbq.pageview();
    };
    router.events.on("routeChangeComplete", handleRouteChange);
    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, [router.events]);

  return (
    <ApolloProvider client={apolloClient}>
      <ScriptHead />
      <StructuredData />

      <ChakraProvider theme={theme}>
        <Preview isPreview={isPreview} />
        <CSSReset />
        <Fonts />
        {getLayout(<Component {...pageProps} />)}
      </ChakraProvider>
    </ApolloProvider>
  );
}

export default MyApp;
