import Head from "next/head";

type StructuredDataProps = {
  data?: {
    [key: string]: any;
  };
};

export default function StructuredData({ data = {} }: StructuredDataProps) {
  return (
    <Head>
      <script
        key="StructuredData"
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: JSON.stringify({ ...defaultData, ...data }),
        }}
      />
    </Head>
  );
}

const defaultData = {
  "@context": "https://schema.org",
  "@type": "WebSite",
  name: "GrowthDeputy",
  description:
    "We help businesses of all sizes achieve their next level of growth by leveraging our remote marketing team. Every Deputy is carefully selected and handles those manual and time-consuming marketing tasks so you can focus on growing your business.",
  alternateName: "Growth Deputy",
  url: "https://www.growthdeputy.com/",
  sameAs: [
    "https://www.linkedin.com/company/growthdeputy/",
    "https://www.facebook.com/GrowthDeputy",
  ],
  author: {
    "@type": "Organization",
    legalName: "GrowthDeputy",
  },
};
