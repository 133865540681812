export const colors = {
  primary: {
    darkgreen: "#2D7339",
    lightgreen: "#7EBF77",
  },
  secondary: {
    white: "#FFFFFF",
    typography: "#171923",
    orange: "#F17A42",
    yellow: "#EFD373",
    bluegreen: "#1B657F",
    black: "#000000",
  },
};
