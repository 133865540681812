declare global {
  // eslint-disable-next-line no-unused-vars
  interface Window {
    fbq: any;
  }
}

export const FB_PIXEL_ID = process.env.NEXT_PUBLIC_FACEBOOK_PIXEL_ID;

export const LINKEDIN_PIXEL_ID = process.env.NEXT_PUBLIC_LINKEDIN_PIXEL_ID;

export const pageview = () => {
  window.fbq("track", "PageView");
};

export const event = (name: string, options = {}) => {
  window.fbq("track", name, options);
};
