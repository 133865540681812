import { ApolloClient, InMemoryCache } from "@apollo/client";
import getConfig from "next/config";

const { publicRuntimeConfig } = getConfig();

const { strapiGraphqlUrl } = publicRuntimeConfig;

const client = new ApolloClient({
  uri: strapiGraphqlUrl,
  ssrMode: typeof window === "undefined",
  cache: new InMemoryCache(),
});

export default client;
